import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import "../css/sharan-style.css";
import "../css/animate.min.css";
import "../css/style.css";
import CardNewsReusable from "./card-news-reusable";


import "../css/swiper-bundle.min.css";
import Swiper, { EffectCoverflow, Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';

Swiper.use([EffectCoverflow, Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);


const NewsSlider = ({props, news_section_title, HomeNews}) => {

  const query = useStaticQuery(graphql`
    {
      newsApac: file(relativePath: {eq: "news-apac.png"}) {
        base
        childImageSharp {
          fixed(height: 28) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      newsBTW: file(relativePath: {eq: "news-business-this-week.png"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsDigitalTerminal: file(relativePath: {eq: "news-digital-terminal.png"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsEduTimes: file(relativePath: {eq: "news-education-times.jpg"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsEdugraph: file(relativePath: {eq: "news-edugraph.png"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsETR: file(relativePath: {eq: "news-etr.jpg"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsIndiaToday: file(relativePath: {eq: "news-india-today.png"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsIndianExpress: file(relativePath: {eq: "news-indian-express.jpg"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsK12Digest: file(relativePath: {eq: "news-k12-digest.png"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsHindu: file(relativePath: {eq: "news-the-hindu.png"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsTimesNow: file(relativePath: {eq: "news-times-now.jpg"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
      newsTOI: file(relativePath: {eq: "news-toi.jpg"}) {
          base
          childImageSharp {
            fixed(height: 28) {
              ...GatsbyImageSharpFixed
            }
          }
      }
    }
  `)

  const settings = {
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,

    responsive: [
        {
          breakpoint: 1172,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            
          }
        },
        {
          breakpoint: 756,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 530,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
            breakpoint: 350,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
      ]
  }

  return (
    

    <div class="swiper-wrapper">
        

        <div class="swiper-slide">
        <a href={void(0)} class="feature-box padding-4-half-rem-tb padding-4-rem-lr bg-white box-shadow-small box-shadow-extra-large-hover md-padding-3-rem-all sm-padding-5-rem-all border-top border-width-4px border-color-fast-blue br-10">

      <div class="feature-box-icon margin-20px-bottom">
                                <img src="https://assets.theworldgrad.com//gatsby-assets/images/award-1.png" alt="For Best Talent Team In Online Programs" />
                            </div>
                            <div class="feature-box-content last-paragraph-no-margin">
                                
                                <p>For Best Talent Team In Online Programs</p>
				
                            </div>
</a>
      </div>

      <div class="swiper-slide ">
      <a href={void(0)}  class="feature-box padding-4-half-rem-tb padding-4-rem-lr bg-white box-shadow-small box-shadow-extra-large-hover md-padding-3-rem-all sm-padding-5-rem-all border-top border-width-4px border-color-fast-blue br-10">
      <div class="feature-box-icon margin-20px-bottom">
                                <img src="https://assets.theworldgrad.com//gatsby-assets/images/award-2.png" alt="For Best Future Of Learning Model" />
                            </div>
                            <div class="feature-box-content last-paragraph-no-margin">
                                
                                <p>For Best Future Of Learning Model</p>
				
                            </div>
</a>
      </div>
      


          { HomeNews && HomeNews.length && HomeNews.map((news) => (

              <CardNewsReusable
                alt_text={news.node.title}
                title={news.node.acf.news_content}
                link={news.node.acf.news_content_link}
                logo={news.node.featured_media != null ? news.node.featured_media.source_url : "" }
                articleDate={news.node.acf.news_date}
              />

          ))}
                    </div>
    
        
  )
}
export default NewsSlider
